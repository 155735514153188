// Here you can add other styles
.cameraCss {
    max-height: 1000px !important;
    margin: 0 auto;
    height: auto;
}

.imgResp {
    width: 98%;
}

.div100 {
    width: 100%;
}

.c-main {
    padding-top: 0.5rem;
}

.top-button {
    position: absolute;
    top: 10px;
    z-index: 1049;
    left: 51%;
    transform: translateX(-50%)
}

.top-right {
    position: absolute;
    top: 10px;
    z-index: 1049;
    right: 5%;
}

.top-button-manual {
    position: absolute;
    top: 5px;
    z-index: 1049;
    left: 40%;
}

.order-page-head {
    position: absolute;
    top: 5px;
    z-index: 1049;
}
.order-page-head-left {
    position: absolute;
    top: 5px;
    left: 3%;
    z-index: 1049;
}

.leaflet-container {
    width: 100%;
    height: 80vh;
}

.roscomm {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1049;
}

.infohistory {
    color: #6b6d7a;
    background-color: #f1f2f4;
    font-weight: 200;
}

.disabledbutton {
    pointer-events: none;
    opacity: 0.1;
}

.dispNone {
    display: none !important;
}

.robotstatus {
    margin-left: 40px;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 8px;
}

.robotGPSInfo {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid grey;
    border-radius: 4px;
}

.absJoy {
    position: absolute;
}

.marg-top-5 {
    margin-top: -5px;
}

.card {
    margin-bottom: 1rem;
}

.notifylabel {
    border: solid 1px #39f;
    padding: 4px;
    position: absolute;
    top: 1vh;
    left: 50vw;
    width: auto;
    z-index: 1111;
}

.waypointMapButtons {
    position: absolute;
    top: 1vh;
    right: 5vw;
    width: auto;
    height: 4vh;
    z-index: 1111;
}

.defaultSettings {
    position: relative;
    right: 1vw;
    width: auto;
    z-index: 1111;
}

.saveLocation {
    position: relative;
    right: 1vw;
    width: auto;
    z-index: 1111;
}

.validation {
    position: relative;
    right: 1vw;
    width: auto;
    z-index: 1111;
}
.validateLabel {
    padding: 5px;
    position: relative;
    float:left;
    width: auto;
    left: 0vw;
    z-index: 1111;
}
.delay {
    position: absolute;
    z-index: 1049;
    top: 36px;
    left: 5p;
    margin-left: 10px;
    font-weight: bold;
}
.lidbutton {
    padding: 10px 60px;
    position: absolute;
    top: 5px;
    z-index: 1049;
    left: 50%;
    font-weight: bold;
    margin-left:-100px;
}

.loading-logo {
    animation: loading-logo-spin infinite 5s linear;
    display: block;
    width: 30vmin;
    pointer-events: none;
    border-radius:50%;
}

@keyframes loading-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.in-middle {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 80vh;
}
.cameraRosCss {
    max-height: 1000px !important;
    margin: 2px auto;
    // height: 30vh;
    width: 100%;
}

.cDataTableButtonMargin {
    margin-bottom: 3px;
}

.mapBorder {
    border: 1px solid blue;
    border-radius: 20px;
    // outline: 1px solid blue;
    // outline-offset: -1px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
}

.lightBorder {
    border: 3px solid rgb(106, 175, 42);
    border-radius: 4px;
}
.legendBorder {
    border: 2px solid blue;
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 2px;
}

.robotSelect {
    position: relative;
    top: 1vh;
    left: 5vw;
    width: auto;
    height: 4vh;
    z-index: 1111;
}

.robotIdName {
    background-color: #ced2d8;
    margin: 3px;
}

.glowButton {
    border: 1px transparent;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-animation: glowing 2500ms infinite;
    -moz-animation: glowing 2500ms infinite;
    -o-animation: glowing 2500ms infinite;
    animation: glowing 2500ms infinite;
}

@-webkit-keyframes glowing {
    0% {
      background-color: #0091b2;
      -webkit-box-shadow: 0 0 3px #0091b2;
    }
    50% {
      background-color: #21c7ed;
      -webkit-box-shadow: 0 0 15px #21c7ed;
    }
    100% {
      background-color: #0091b2;
      -webkit-box-shadow: 0 0 3px #0091b2;
    }
}

@keyframes glowing {
    0% {
      background-color: #0091b2;
      box-shadow: 0 0 3px #0091b2;
    }
    50% {
      background-color: #21c7ed;
      box-shadow: 0 0 15px #21c7ed;
    }
    100% {
      background-color: #0091b2;
      box-shadow: 0 0 3px #0091b2;
    }
}

.fpsMessage {
      border: 3px solid grey;
      border-radius: 4px;
      font-size: large;
      padding: 5px;
      margin-top: 5px;
}

.expertMode {
    border: 2px solid grey;
    border-radius: 4px;
    font-size: large;
    font-weight: bold;
    padding: 2px;
    margin-top: 5px;
}

.cameraSelect {
    width: auto;
    border: 2px solid grey;
    border-radius: 4px;
    font-size: medium;
    font-weight: bold;
    margin-top: 5px;
}

.frontLeftAll {
  position: absolute;
  top: 0%;
  left: 7%;
  width: 23%;
  height: 46%;
  object-fit: cover;
}

.rearLeftAll {
    position: absolute;
    bottom: 0%;
    left: 7%;
    width: 23%;
    height: 46%;
    object-fit: cover;
}

.frontRightAll {
    position: absolute;
    top: 0%;
    right: 7%;
    width: 23%;
    height: 46%;
    object-fit: cover;
}

.rearRightAll {
    position: absolute;
    bottom: 0%;
    right: 7%;
    width: 23%;
    height: 46%;
    object-fit: cover;
}

.frontMiddleAll {
    position: absolute;
    top: 10%;
    left: 30%;
    width: 40%;
    height: 80%;
    object-fit: cover;
}

.frontLeft {
  position: absolute;
  bottom: 0%;
  left: 0%;
  width: 25%;
  height: 50%;
  object-fit: cover;
}

.frontRight {
    position: absolute;
    bottom: 0%;
    right: 0%;
    width: 25%;
    height: 50%;
    object-fit: cover;
}

.frontMiddle {
    position: absolute;
    bottom: 0%;
    left: 25%;
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.adminTitle {
    border-top: 2px solid grey;
    border-bottom: 2px solid grey;
    border-radius: 4px;
    font-weight: bold;
    padding: 2px;
    margin-top: 5px;
}

.adminDescription {
    border-left: 2px solid grey;
    border-right: 2px solid grey;
    border-radius: 15px;
    font-weight: bold;
    font-size: large;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
}
.arrowGreen {
    border: solid green;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
}
.arrowBlue {
    border: solid blue;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.legendCircle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.black-text {
    color: black;
}

.data-table-buttons > button {
    margin-left: 2px;
    margin-right: 2px;
}

.white-color-texts > tr {
    color: white;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}

.smallText
{
    li {
        font-size: 11px;
        padding: 0.3rem 1.25rem
    }
}
.xsmallText
{
    li {
        font-size: 8px;
        padding: 0.4rem 1.25rem
    }
}
.leaflet-xs
{
    height: 200px !important;
}

.fc-daygrid-event-harness, .fc-timegrid-event-harness { width: 50%; float: left; }

.fc-timegrid-event-harness:nth-child(1n) { inset: 0px 0% -21px 0% ! important; }
.fc-timegrid-event-harness:nth-child(2n) { inset: 0px 0% -21px 50% ! important; }

.glowingColorful::before{
    content: '';
    position: absolute;
    background-size: 400%;
    width: calc(100%);
    height: calc(100%);
    z-index: -1;
    border-radius: 5px;
    animation: glowing 2s linear infinite;
}

.time-slot-card {
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-bottom: 5px; 
  }
  
  .time-slot-card.selected {
    background-color: #3C4B64;
    color: #fff; 
  }
  
  .time-slot-card.disabled {
    background-color: #ddd; 
    cursor: not-allowed;
  }
  
  .time-range {
    font-size: 16px;
    color: #333;
    text-align: center;
  }

  .verification-input {
    margin: 0 3px;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    border: solid 1px #ccc;
    box-shadow: 0 0 5px #ccc inset;
    outline: none;
    // width: 11%;
    transition: all .2s ease-in-out;
    border-radius: 3px;
    
    &:focus {
      border-color: #3399FF;
      box-shadow: 0 0 5px #3399FF inset;
    }
    
    &::selection {
      background: transparent;
    }
  }
  .nonborder-td {
    border: 0px !important;
    border-top: 1px solid !important;
    padding: 4px !important;
    border-top-color: #d8dbe0 !important;
  }
  .hover-row tr:hover {
    background-color: $light;
    cursor: pointer;
  }

  .rounded-table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 0 !important;
  }
  
  .rounded-table td, .rounded-table th {
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .rounded-table thead tr:first-child th:first-child {
    border-top-left-radius: 20px;
  }
  
  .rounded-table thead tr:first-child th:last-child {
    border-top-right-radius: 20px;
  }
  
  .rounded-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
  }
  
  .rounded-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
  }

  .top-center-button-container {
    position: fixed;   /* Fixes the container to the viewport */
    top: 0;            /* Stick to the top */
    left: 50%;         /* Move to the center horizontally */
    transform: translateX(-50%);  /* Center the container */
    margin-top: 10px;  /* Optional: Give some space from the top */
    display: flex;     /* Use flexbox to place buttons side by side */
    gap: 10px;         /* Optional: Adds space between the buttons */
    z-index: 9999;     /* Make sure it’s on top of other elements */
  }
  .circular-progress-bar {
    width: 50px;
    height: 50px;
  }